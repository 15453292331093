@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,900");
@tailwind base;
@tailwind components;
@tailwind utilities;

#overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  /* @include vendor(
    'animation',
    'overlay #{_duration(overlay)} #{$delay-overlay} forwards'
  ); */
  background-attachment: fixed, fixed;
  background-image: url("images/overlay-pattern.png"), url("images/overlay.svg");
  background-position: top left, center center;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  bottom: 10px;
  position: fixed;
  right: 10px;
}

.bingo-bottom {
  height: 20vmin;
  pointer-events: none;
  bottom: 10px;
  position: fixed;
  right: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  /* background-color: #4c7ab6; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Source Sans Pro", sans-serif;
}

.App-header h1 {
  color: gold;
  font-size: calc(10px + 4vw);
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-size: xx-large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-family: "bluey-regular", sans-serif;
  letter-spacing: 0.1em;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.animate-carousel {
  animation: marquee 90s linear infinite;
}
.animate-carousel:hover {
  animation-play-state: paused;
}
